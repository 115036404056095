<template>
  <v-expansion-panels v-if="chicklets.length" class="panel">
    <v-expansion-panel class="mt-4 mb-2">
      <v-expansion-panel-header class="sectionTitle subheading">{{ category }} Applications</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container fluid grid-list-xl>
          <div v-swiper="swiperOptionsObject" :instance-name="category">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="app in chicklets"
                :key="app.productName"
              >
                <div>
                  <v-card
                    class="card pa-3"
                    flat
                    :key="app.sortOrder"
                    background="#fafafa"
                  >
                    <v-hover v-slot="{ hover }" open-delay="100">
                      <v-btn
                        class="mx-3 pa-0"
                        :width="chickletWidth"
                        :height="chickletHeight"
                        background="#fafafa"
                        :color="(checkIfSubscribed(app)) ? '#09A1E0' : 'white' "
                        v-on:click="checkIfSubscribed(app) ? buttonClick(app.loginUrl): createPopup(app)"
                      >
                        <v-list-item three-line>
                          <div
                            id="appContent"
                            :class="[(checkIfSubscribed(app)) ? '' : 'unsubscribed']"
                          >
                            <v-list-item-content v-if="checkIfSubscribed(app)" class="ml-2">
                              <v-list-item-title
                                class="white--text medCardTitle ml-2"
                                v-html="formatName(app.productName)" />
                              <v-btn
                                @click.native.stop.prevent
                                v-for="tag in app.tags"
                                :key="tag"
                                height="18"
                                :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                                class="categoryTag white--text ml-2"
                                outlined
                              >{{tag.trim()}}</v-btn>
                              <v-card-actions id="cardActions">
                                <v-btn
                                  id="cardActions"
                                  v-on:click="buttonClick(app.loginUrl)"
                                  class="ml-2"
                                  text
                                  small
                                  color="white"
                                >Open App &#8594;</v-btn>
                              </v-card-actions>
                            </v-list-item-content>

                            <v-list-item-content v-if="!checkIfSubscribed(app)" class="ml-2">
                              <v-list-item-title
                                v-if="!hover"
                                :class="(app.isSubscribed == true ) ? 'white--text' : '#09A1E0--text'"
                                class="medCardTitle #09A1E0--text"
                                v-html="formatName(app.productName)" />
                              <div v-if="!hover">
                                <v-btn
                                  height="18"
                                  v-for="tag in app.tags"
                                  :key="tag"
                                  :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                                  class="categoryTag"
                                  outlined
                                  color='blue'
                                >{{tag.trim()}}</v-btn>
                              </div>
                              <v-list-item-subtitle
                                v-if="hover"
                                class="#09A1E0--text"
                                id="hoverDesc"
                                :title="app.desc"
                              >{{app.desc}}</v-list-item-subtitle>
                              <v-card-actions id="cardActions">
                                <v-btn
                                  v-if="!hover"
                                  id="cardActions"
                                  v-on:click="createPopup(app)"
                                  class="ml-2"
                                  text
                                  small
                                  color="#09A1E0"
                                >More Info &#8594;</v-btn>
                              </v-card-actions>
                            </v-list-item-content>
                          </div>
                          <v-img
                            width="95"
                            height="170"
                            class="mr-0 float-right imgBorder"
                            :src="app.img + imageSize.small"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular indeterminate color="grey lighten-5" />
                              </v-row>
                            </template>
                          </v-img>
                        </v-list-item>
                      </v-btn>
                    </v-hover>
                  </v-card>
                </div>
              </div>
            </div>

            <div class="swiper-button-next" />
            <div class="swiper-button-prev" />
          </div>     
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>


<script>
import { directive } from "vue-awesome-swiper";
import { Swiper as SwiperClass, Navigation, Mousewheel } from "swiper/core";
//import "swiper-bundle.css";

SwiperClass.use([Navigation, Mousewheel]);

export default {
  directives: {
    swiper: directive
  },
  props: {
    category: String,
    appsList: Array,
    subscribedList: Array,
    imageSize: Object,
  },
  data() {
    return {
      chickletWidth: "292",
      chickletHeight: "170",
      logoHeight: "33px",
      chicklets: [],
      swiperOptionsObject: {
        mousewheel: true,
        freeMode: true,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
    };
  },
  created() {
    this.chicklets = this.appsList.filter(app => app.tags.includes(this.category));
  },
  methods: {
    checkIfSubscribed(app) {
      return this.subscribedList.includes(app.oktaId);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    createPopup(app) {
      this.$emit("cardClick", app);
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  }
};
</script>


<style scoped>
.panel {
  margin-left: 15px;
  background: #fafafa !important;
}

.subheading {
  margin-left: 5px !important;
  margin-top: 4px;
}

.swiper-wrapper {
  margin-left: -15px;
}
</style>
