import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import VueGtag from "vue-gtag";

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from '@/router';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
}, router);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
