<template>
  <v-footer class="
    font-weight-medium
    mt-12
    mb-3
    footer-section
  ">
    <div class="footer-container d-flex flex-column align-center">
      <v-img
        class="footer-img mb-4"
        height="30"
        max-width="140"
        src="@/assets/moodys-logo.svg"
      />
      <a
        class="footer-link"
        href="https://www.moodys.com/termsofuseinfo.aspx?lang=en&cy=global"
        target="_blank"
        >Terms of Service</a>
      <a
        class="footer-link"
        href="https://www.moodys.com/privatepolicy.aspx?lang=en&cy=global"
        target="_blank"
        >Privacy Policy</a>
      <a
        class="footer-link"
        href="/help"
        >Help</a>
      <span class="app-version">
        v.{{ appVersion }}
      </span>
    </div>
  </v-footer>
</template>


<script>
export default {
  name: 'Footer',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION
    };
  }
}
</script>


<style scoped>
.footer-section{
  background-color: #fafafa !important
}

.footer-container {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.app-version {
  font-style: italic;
  color: #aaa;
}
</style>
