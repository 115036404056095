<template>
  <div>
    <v-container fluid grid-list-xl>
      <h1 class="sectionTitle ml-6 mt-12">
        Search Results for {{searchKeyword}}:
      </h1>
      <v-row justify="center">
        <v-card
          class="card pa-3"
          flat
          v-for="app in chicklets"
          :key="app.productName"
          background="#fafafa"
        >
          <v-btn
            class="mx-3 pa-0"
            :width="chickletWidth"
            :height="chickletHeight"
            background="#fafafa"
            v-on:click="createPopup(app)"
            :color="(checkIfSubscribed(app)) ? '#09A1E0' : '#fafafa' "
          >
            <v-list-item three-line>
              <div id="appContent">
                <v-list-item-content class="ml-2">
                  <v-list-item-title
                    :class="(checkIfSubscribed(app)) ? 'white--text' : '#09A1E0--text'"
                    class="cardTitle"
                    v-html="formatName(app.productName)" />
                  <v-list-item-subtitle
                    :class="(checkIfSubscribed(app)) ? 'white--text' : '#09A1E0--text'"
                    id="cardSubtitle"
                    :title="app.desc"
                  >{{app.desc}}</v-list-item-subtitle>
                </v-list-item-content>
              </div>
              <img
                width="95"
                height="170"
                class="mr-0 float-right imgBorder"
                :src="app.img + imageSize.small"
                :alt="app.productName"
              />
            </v-list-item>
          </v-btn>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>


<script>
export default {
  props: {
    appsList: Array,
    subscribedList: Array,
    imageSize: Object,
  },
  data() {
    return {
      searchKeyword: this.$route.params.keyword.replace(/%20/g, " "),
      chicklets: [],
      chickletWidth: "292",
      chickletHeight: "170",
    };
  },
  watch: {
    appsList: "getApps",
    '$route': function(route) {
      this.searchKeyword = route.params.keyword.replace(/%20/g, " ");
      this.getApps();
    },
  },
  created() {
    this.getApps();
  },
  methods: {
    getApps() {
        const searchString = this.searchKeyword.trim().toLowerCase();
        this.chicklets = this.appsList.filter(app => 
          app.productName.toLowerCase().includes(searchString) 
          || app.desc.toLowerCase().includes(searchString));
    },
    checkIfSubscribed(app) {
      return this.subscribedList.includes(app.oktaId);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    createPopup(app) {
      this.$emit("cardClick", app);
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  }
};
</script>


<style scoped>
::-webkit-scrollbar {
  display: none;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  height: 190px;
}

.card {
  display: inline-block;
  background: #fafafa;
}

.scrollrow {
  display: inline !important;
}
</style>
