<template>
  <v-container fluid color-white class="mt-12">
    <v-row
      class="mt-12"
      justify="center"
      align="center"
    >
      <v-col sm=12 class="mt-12">
        <h3>
          Page not found! Please contact our administrator in the
          <a href="/help">Help Page</a>.
        </h3>
        <v-row width="50%" justify="center">
          <v-btn
            @click="goToDash()"
            class="
              mt-6
              mx-2
              v-btn
              v-btn--contained
              theme--light
              v-size--default
              primary
            "
          >Go to the Home page</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "request",
  methods: {
    goToDash() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>


<style scoped>
h3 {
  text-align: center;
}
</style>
