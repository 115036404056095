<template>
  <v-container fluid grid-list-xl data-cy='home-page-container'>
    <Trending
      :appsList="appsList"
      :subscribedList="subscribedList"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />

    <v-divider class="divider" />

    <Subscribed class="extra-top-margin"
      :appsList="appsList"
      :subscriptions="subscriptions"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />

    <v-divider class="divider" />

    <Popular class="extra-top-margin"
      :appsList="appsList"
      :subscribedList="subscribedList"
      :appCategories="appCategories"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />

    <NewlyAdded
      :appsList="appsList"
      :subscribedList="subscribedList"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />

    <Promoted
      :appsList="appsList"
      :subscribedList="subscribedList"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />

    <CategoryGroup 
      v-for="category in appCategories" 
      :key="category" 
      :category="category"
      :appsList="appsList"
      :subscribedList="subscribedList"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />

    <Categories
      :appsTracker="appsTracker"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />
    <Footer />
  </v-container>
</template>


<script>
import Trending from "@/components/Trending";
import Subscribed from "@/components/Subscribed";
import Promoted from "@/components/Promoted";
import Popular from "@/components/MostPopular";
import NewlyAdded from "@/components/NewlyAdded";
import Categories from "@/components/AppCategories";
import Footer from "@/components/Footer";
import CategoryGroup from "@/components/CategoryGroup";

export default {
  components: {
    Trending,
    Subscribed,
    Promoted,
    Popular,
    NewlyAdded,
    Categories,
    Footer,
    CategoryGroup,
  },
  props: {
    appsList: Array,
    appsTracker: Object,
    subscribedList: Array,
    subscriptions: Array,
    appCategories: Array,
    imageSize: Object,
  },
  methods: {
    setTargetApp(app) {
      this.$emit("cardClick", app);
    },
  }
};
</script>


<style>
.container {
  padding: 0 12px 12px 0 !important;
}

.swiper-slide {
  width: unset !important;
  margin-right: 5px !important;
}

.swiper-button-next {
  height: 100%;
  position: absolute;
  right: 0;
  top: 10%;
  width: 10%;
  background-image: linear-gradient(
    to right,
    rgba(250, 250, 250, 0),
    rgba(250, 250, 250, 1)
  );
}

.swiper-button-prev {
  height: 100%;
  position: absolute;
  left: 0;
  top: 10%;
  width: 10%;
  background-image: linear-gradient(
    to left,
    rgba(250, 250, 250, 0),
    rgba(250, 250, 250, 1)
  );
}

.swiper-button-next:after {
  font-size: 14px;
  font-weight: bolder;
  color: black;
  position: absolute;
  right: 10px;
}

.swiper-button-prev:after {
  font-size: 14px;
  font-weight: bolder;
  color: black;
  position: absolute;
  left: 10px;
}

.swiper-button-disabled {
  visibility: hidden;
}

.v-btn {
  min-width: unset !important;
}

::-webkit-scrollbar {
  display: none;
}

.card {
  display: inline-block;
  background: #fafafa;
}

.subheading {
  margin-bottom: 6px !important;
}

.divider {
  position: relative;
  left: -1px;
  width: 100%;
}

.extra-top-margin {
  margin-top:25px;
}
</style>
