import _Vue from "vue";
import { MapSSO } from "@moodys-ma-platform/auth-sdk";

const _ssoService = new MapSSO({
    clientId: process.env.VUE_APP_SSO_CLIENT_ID,
    redirectUri: window.location.protocol + '//' + window.location.host + '/callback',
    issuer: `${process.env.VUE_APP_SSO_DOMAIN}`
});

const guardHandler = async (originalUri) => {
    _ssoService.setOriginalUri(originalUri)

    await _ssoService.signInWithRedirect()
}

export const NavigationGuardMixin = _Vue.extend({
    async beforeRouteEnter (to, from, next) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // track the originalUri for guardHandler
            const originalUri = window.location.origin + to.fullPath

            // guard the secure route based on if the user is authenticated
            const isAuthenticated = await _ssoService.isAuthenticated()

            if (!isAuthenticated) {
                await guardHandler(originalUri)
            } else {
                next()
            }
        } else {
            next()
        }
    }
})

async function install(Vue) {
    Vue.mixin(NavigationGuardMixin)

    Vue.prototype.$authService = _ssoService

    await _ssoService.start()
}

export default { install }