<template>
  <v-app>
    <v-app-bar
      class="navbar"
      clipped-left
      fixed
      app
    >
      <v-toolbar-title class="headline text-uppercase">
        <router-link to="/" class="logo-container">
          <v-img
            height="41px"
            width="121px"
            contain
            src="./assets/moodys-logo.svg"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <SearchBar
        v-if="authenticated"
        :apps="appNames"
        :imageSize="imageSize"
        v-on:searchSubmitted="searchQuery"
      />
      <v-spacer />
      <ProfileButton v-if="authenticated" :userInfo="userInfo" />
    </v-app-bar>
    <v-main class="main-section">
      <router-view
        :appsList="apps"
        :appsTracker="appsTracker"
        :subscribedList="subscribedAppIds"
        :subscriptions="subscriptions"
        :appCategories="categories"
        :appNames="appNames"
        :helpAppNames="helpAppNames"
        :userInfo="userInfo"
        :imageSize="imageSize"
        v-on:cardClick="setTargetApp"
        v-on:displayAlert="setUserFeedback"
        v-on:syncUserInfo="getUserInfo"
        v-on:redirectAfterResetPW="redirectAfterResetPW"
        class="ml-4"
      />
    </v-main>

    <v-dialog v-model="dialog" max-width="480px">
      <v-card class="mx-auto">
        <v-img
          class="white--text align-end"
          height="200px"
          src="./assets/longReis.png"
        />

        <v-card-title>{{ targetApp.productName }}</v-card-title>

        <v-card-text class="text--primary">
          <div>{{ targetApp.longDesc }}</div>
        </v-card-text>

        <v-card-actions class="ml-4">
          <v-btn
            outlined
            medium
            color="blue"
            v-on:click="visitExternalLink(targetApp.linkUrl)"
            >View Website &#8594;</v-btn>
          <v-btn
            depressed
            medium
            dark
            color="blue"
            class="ml-6"
            v-on:click="requestAccess(targetApp.productName)"
          ><span class="mx-3">Request Access &#8594;</span></v-btn>
        </v-card-actions>

        <v-divider class="mt-4" />
      </v-card>
    </v-dialog>

    <v-dialog v-model="userFeedbackModal" max-width="540px">
      <v-card>
        <v-card-title>
          <span 
            v-if="isError" 
            class="headline" 
            color="red darken-1"
          >Error</span>
          <span 
            v-else 
            class="headline" 
            color="blue darken-1"
          >Success</span>
        </v-card-title>
        <v-card-text>
          <h3
            v-for="errorMessage in feedbackMessagesList"
            :key="errorMessage"
            class="font-weight-light mt-4"
          >
            {{ errorMessage }}
          </h3>
          <h3 v-if="isError" class="font-weight-light mt-4">
            Please try again later.
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn 
            :color="isError 
              ? 'red darken-1' 
              : 'blue darken-1'" 
            text 
            @click="closeFeedbackModal"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>


<script>
import axios from 'axios';

import SearchBar from "./components/Searchbar";
import ProfileButton from "./components/ProfileButton";

/* eslint-disable no-console */
export default {
  name: "App",
  components: {
    ProfileButton,
    SearchBar
  },
  data() {
    return {
      authenticated: false,
      userInfo: null,
      appNames: [],
      helpAppNames: [],
      appsTracker: {},
      categories:[],
      subscriptions: [],
      subscribedAppIds: [],
      apps: [],
      dialog: false,
      targetApp: {},
      isError: false,
      userFeedbackModal: false,
      feedbackMessagesList: [],
      redirectURL: null,
      retriesCounter: 0,
      imageSize: {
        small: '&w=150',
        medium: '&w=300',
        large: '&w=1500',
      },
    };
  },
  watch: {
    '$route': function(newRoute, oldRoute) {
      if (oldRoute.fullPath && oldRoute.fullPath !== newRoute.fullPath) {
        this.setupUser();
      }
    },
    'userFeedbackModal': function(newState) {
      if (!newState) {
        this.isError = false;
        this.feedbackMessagesList = [];
        if (this.redirectURL) {
          window.location.href = this.redirectURL
          this.redirectURL = null
        }
      }
    }
  },
  created() {
    this.getProductCatalog();
    this.setupUser();
  },
  methods: {
    async getProductCatalog() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_CATALOG_URL}/products`,
          { headers: {
            "Content-Type": "application/json"
          }}
        );
        
        // console.log(response.data.Items.map(e => e.customData && e.customData["SSO Dashboard"]?.index))
        const allowedApps = response.data.Items
          .filter(product => product.dashboardEnabled)
          .map(item => ({
            oktaId: item.okta.id,
            productName: item.productName,
            desc: item.text.description,
            longDesc: item.text.longDescription || item.text.description,
            loginUrl: item.links.loginUrl || item.links.productUrl || '/404',
            linkUrl: item.links.productUrl || '/404',
            img: item.images.image,
            promoted: item.featured,
            trending: item.trending,
            popular: item.popular,
            newAdd: item.newAdd,
            tags: item.tags.split(';'),
            salesforce: item.salesforce,
          }));
        this.apps = [...allowedApps];

        const tagsCount = {};
        this.appNames = allowedApps.map(product => {
          // unrelated to map but included to minimize time coefficient; populates tagsCount
          product.tags.forEach(rawTag => {
            const tag = rawTag.trim();
            if (!tagsCount[tag]) {
              tagsCount[tag] = 1;
            } else {
              tagsCount[tag] += 1;
            }
          });

          return product.productName;
        }).sort((a, b) => a.localeCompare(b));
        this.helpAppNames = response.data.Items
          .filter(item => item.helpMenuEnabled)
          .map(item => item.productName)
          .sort((a, b) => a.localeCompare(b))
        this.categories = [...Object.keys(tagsCount)];
        this.appsTracker = tagsCount;
      } catch(err) {
        console.dir(err);
        if (err.code !== "ECONNABORTED") {
          this.setUserFeedback('The products data is unavailable.', true);
        }
      }
    },
    async setupUser() {
      try {
        this.authenticated = await this.$authService.isAuthenticated();
        if (!this.userInfo && this.authenticated) {
          if (this.$authService.tokenManager?.accessToken?.token) {
            this.getUserInfo();
            this.getUserApps();
          } else if (this.retriesCounter < 5) {
            this.retriesCounter += 1;
            setTimeout(this.setupUser, this.retriesCounter * 500);
          } else {
            throw new Error('User authenticated but not able to retrieve their ACCESS TOKEN');
          }
        }
      } catch(err) {
        console.dir(err);
        this.setUserFeedback('Something went wrong with your session.', true);
        await this.$authService.stop();
        await this.$authService.signOut();
      }
    },
    async getUserInfo() {
      try {
        this.userInfo = await this.$authService.getUserInfo();
      } catch(err) {
        console.dir(err);
        this.setUserFeedback('Your profile data is unavailable.', true);
      }
    }, 
    async getUserApps() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_SSO_API_URL}/user/apps?version=${process.env.VUE_APP_VERSION}`,
          { headers: {
            Authorization: `Bearer ${this.$authService.tokenManager.accessToken.token} ${process.env.VUE_APP_VERSION}`,
          }}
        );

        this.subscriptions = data;
        this.subscribedAppIds = data.map(product => product.appInstanceId);
      } catch (err) {
        console.dir(err);
        this.setUserFeedback('Your apps data is unavailable.', true);
      }
    },
    searchQuery(query, event) {
      let keyword;
      if (query) {
        keyword = query;
      } else if (event && event.code) {
        keyword = event.target.value;
      }
      if (keyword && this.$route.params.keyword !== keyword) {
        this.$router.push(`/search/${keyword}`);
      }
    },
    setTargetApp(app) {
      this.dialog = true;
      this.targetApp = app;
    },
    requestAccess(appName) {
      this.dialog = false;
      this.targetApp = {};
      this.$router.push(`/help?action=access&app=${appName}`);
    },
    setUserFeedback(message, isError) {
      this.isError = isError;
      this.userFeedbackModal = true;
      if (!this.feedbackMessagesList.includes(message)) {
        this.feedbackMessagesList.push(message);
      }
    },
    closeFeedbackModal() {
      this.userFeedbackModal = false;
      if (window.location.href.includes('?help-request=submitted')) {
        this.$router.replace('help');
      }
    },
    redirectAfterResetPW(url) {
      this.redirectURL = url
    },
    visitExternalLink(href) {
      window.open(href, "_blank");
    },
  }
};
</script>


<style lang="scss" scoped>
.navbar {
  background-color: white !important;
}
.main-section {
  background: #fafafa;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: .3rem;
}
</style>
