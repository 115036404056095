<template>
  <v-container
    fluid
    grid-list-md
    text-xs-center
  >
    <v-row justify="center" align="center">
      <v-card
        :width="700"
        :height="500"
        class="mt-8 row-card"
      >
        <v-tabs
          class="card-tabs"
          vertical
          color="#09A1E0"
          v-model="activeTab"
        >
          <v-tab key="security">Password</v-tab>
          <v-tab-item key="security">
            <ChangePassword 
              :userInfo="userInfo"
              v-on:displayAlert="setUserFeedback"
            />
          </v-tab-item>
        </v-tabs> 
      </v-card>
    </v-row>
  </v-container>
</template>


<script>
// import Profile from "@/components/Profile";
import ChangePassword from "@/components/ChangePassword";

export default {
  name: "settings",
  components: {
    // Profile,
    ChangePassword,
  },
  props: {
    focusTab: Number,
    userInfo: Object,
  },
  data() {
    return {
      activeTab: this.focusTab,
    }
  },
  methods: {
    setUserFeedback(message, type) {
      this.$emit("displayAlert", message, type)
    },
    syncUserInfo(userId) {
      this.$emit("syncUserInfo", userId);
    }
  }
};
</script>

<style scoped>
.row-card {
  margin-bottom: 40px;
}
.card-tabs {
  width: initial;
  margin-left: 10px;
}
</style>