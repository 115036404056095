<template>
  <v-row justify="center">
    <v-spacer />
    <v-autocomplete
      class="searchbar"
      prepend-inner-icon="mdi-magnify"
      dark
      flat
      hide-details
      dense
      solo-inverted
      clearable
      outlined
      label="Search Moody's Applications..."
      noDataText="No results found"
      :items="items"
      v-model="query"
      @input="submitSearch"
      @keyup.enter="submitSearch"
      @click="submitSearch"
      @blur="clearQuery"
    />
    <v-spacer />
  </v-row>
</template>


<script>
export default {
  props: {
    apps: Array,
  },
  data() {
    return {
      query: null,
      items: this.apps,
    };
  },
  watch: {
    apps: function() {
      this.items = this.apps
    }
  },
  methods: {
    submitSearch(event) {
      this.$emit("searchSubmitted", this.query, event);
    },
    clearQuery() {
      this.query = null;
    }
  },
};
</script>


<style>
.searchbar {
  box-shadow: 0 0 0 0.5pt #c0c0c0 !important;
  border-color: #464646 !important;
}
</style>
