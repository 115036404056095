<template>
  <div>
    <v-container fluid grid-list-xl>
      <h1 class="sectionTitle ml-6 subheading">New Additions</h1>
      <div v-swiper="swiperOptionsObject" :instance-name="category">
        <div v-if="chicklets.length" class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="app in chicklets"
            :key="app.productName"
          >
            <div>
              <v-card
                class="card pa-3"
                flat
                :key="app.sortOrder"
                background="#fafafa"
              >
                <v-hover v-slot="{ hover }" open-delay="100">
                  <v-btn
                    class="mx-3 pa-0"
                    :width="chickletWidth"
                    :height="chickletHeight"
                    background="#fafafa"
                    :color="(checkIfSubscribed(app)) ? '#09A1E0' : 'white' "
                    v-on:click="checkIfSubscribed(app) ? buttonClick(app.loginUrl): createPopup(app)"
                  >
                    <v-list-item three-line>
                      <div
                        id="appContent"
                        :class="[(checkIfSubscribed(app)) ? '' : 'unsubscribed']"
                      >
                        <v-list-item-content v-if="checkIfSubscribed(app)" class="ml-2">
                          <v-list-item-title 
                            class="white--text medCardTitle ml-2" 
                            v-html="formatName(app.productName)" />
                          <v-btn
                            @click.native.stop.prevent
                            v-for="tag in app.tags"
                            :key="tag"
                            x-small
                            class="mb-2 ml-2"
                            height="20"
                            outlined
                            color="white"
                            :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                          >{{tag.trim()}}</v-btn>

                          <v-card-actions id="cardActions">
                            <v-btn
                              id="cardActions"
                              v-on:click="buttonClick(app.loginUrl)"
                              class="ml-2"
                              text
                              small
                              color="white"
                            >Open App &#8594;</v-btn>
                          </v-card-actions>
                        </v-list-item-content>

                        <v-list-item-content v-if="!checkIfSubscribed(app)" class="ml-2">
                          <v-list-item-title
                            v-if="!hover"
                            class="medCardTitle #09A1E0--text"
                            v-html="formatName(app.productName)" />
                          <div v-if="!hover">
                            <v-btn
                              @click.native.stop.prevent
                              v-for="tag in app.tags"
                              :key="tag"
                              height="18"
                              :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                              class="categoryTag"
                              outlined
                              color='blue'
                            >{{tag.trim()}}</v-btn>
                          </div>
                          <v-list-item-subtitle
                            v-if="hover"
                            :class="(app.isSubscribed == true ) ? 'white--text' : '#09A1E0--text'"
                            id="hoverDesc"
                            :title="app.desc"
                          >{{app.desc}}</v-list-item-subtitle>
                          <v-card-actions v-if="!hover" id="cardActions">
                            <v-btn
                              @click.native.stop.prevent
                              v-for="tag in app.tags"
                              :key="tag"
                              id="cardActions"
                              v-on:click="createPopup(app)"
                              class="ml-2"
                              text
                              small
                              color="#09A1E0"
                            >More Info &#8594;</v-btn>
                          </v-card-actions>
                        </v-list-item-content>
                      </div>
                      <v-img
                        width="95"
                        height="170"
                        class="mr-0 float-right imgBorder"
                        :src="app.img + imageSize.small"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5" />
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item>
                  </v-btn>
                </v-hover>
              </v-card>
            </div>
          </div>
        </div>

        <div v-else class="swiper-wrapper">
          <v-card class="card pa-3" flat :height="chickletHeight" />
        </div>
        
        <div class="swiper-button-next" />
        <div class="swiper-button-prev" />
      </div>
    </v-container>
  </div>
</template>


<script>
import { directive } from "vue-awesome-swiper";
import { Swiper as SwiperClass, Navigation, Mousewheel } from "swiper/core";
//import "swiper-bundle.css";

SwiperClass.use([Navigation, Mousewheel]);

export default {
  directives: {
    swiper: directive
  },
  props: {
    appsList: Array,
    subscribedList: Array,
    category: String,
    imageSize: Object,
  },
  data() {
    return {
      chickletWidth: "292",
      chickletHeight: "170",
      logoHeight: "33px",
      chicklets: [],
      swiperOptionsObject: {
        mousewheel: true,
        freeMode: true,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
    };
  },
  watch: {
    appsList: "getApps",
  },
  created() {
    this.getApps();
  },
  methods: {
    getApps() {
      this.chicklets = this.appsList.filter(app => app.newAdd);
    },
    checkIfSubscribed(app) {
      return this.subscribedList.includes(app.oktaId);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    createPopup(app) {
      this.$emit("cardClick", app);
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  }
};
</script>
