<template>
  <v-layout v-if="loading" justify-center>
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    />
  </v-layout>
  <v-container
    v-else
    fluid
    color-white
    class="mt-12"
  >
    <v-row class="mt-12" justify="center">
      <v-container
        class="container-div"
        fluid
        color-white
      >
        <v-card
          v-if="!errorParsingToken"
          class="mx-auto"
          max-width="450"
          elevation="0"
        >
          <v-row class="ma-4" justify="center">
            <v-col class="shrink">
              <v-img src="ma.png" class="welcome-img ml-3" />
            </v-col>
            <v-col md="auto">
              <h2 class="welcomeHeaderSubtext" justify="center">
                Welcome! Finish creating your Moody's Account.
              </h2>
              <h3 class="passDesc" justify="center">
                Password requirements:
              </h3>
              <ul>
                <li class="passReq">At least 8 characters</li>
                <li class="passReq">At least 1 lowercase letter (a-z)</li>
                <li class="passReq">At least 1 uppercase letter (A-Z)</li>
                <li class="passReq">At least 1 number (0-9)</li>
                <li class="passReq">Does not contain any part of your username</li>
              </ul>

              <v-row justify="center">
                <v-col cols="12" md="12">
                  <v-form ref="form" v-model="valid">
                    <v-text-field
                      v-model="newPassword"
                      label="Enter New Password"
                      :rules="passwordRules"
                      required
                      :type="showNewPassword ? 'text' : 'password'"
                      :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showNewPassword = !showNewPassword"
                    />
                    <v-text-field
                      v-model="confirmNewPassword"
                      label="Confirm New Password"
                      :rules="confirmPasswordRules"
                      required
                      :type="showConfirmPassword ? 'text' : 'password'"
                      :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                    />
                    <v-row class="mt-6" justify="center">
                      <v-btn
                        class="create-account white--text"
                        x-large
                        width="375"
                        height="50"
                        @click="savePassword"
                        :disabled="!valid"
                        :color="valid ? '#029AE1' : null"
                      >Create My Account</v-btn>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-card>

        <v-card
          v-else
          justify="center"
          class="mx-auto v-card v-sheet theme--light"
          width="500"
          height="250"
          elevation="0"
        >
          <v-row class="mt-12" justify="center">
            <v-col
              md="auto"
              justify="center"
              class="mt-12"
            >
              <div class="ma-6">
                <h3>
                  You are currently not authorized to perform this operation.
                </h3>
                <br/> 
                <h3>
                  Please contact our administrator in the
                  <a href="/help">Help Page</a>
                  to resolve this issue.
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>


<script>
import axios from 'axios';
import rules from '../utils/field-validations';

export default {
  name: "welcome",
  data() {
    return {
      valid: true,
      activeToken: this.getUrlToken(),
      fromUri: this.getFromURI(),
      stateToken: undefined,
      newPassword: undefined,
      confirmNewPassword: undefined,
      showNewPassword: false,
      showConfirmPassword: false,
      passwordRules: [...rules.password],
      confirmPasswordRules: [ v => v === this.newPassword || "Passwords do not match" ],
      loading: false,
      errorParsingToken: false
    };
  },
  created() {
    this.verifyRecoveryToken();
  },
  methods: {
    getUrlToken() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const activeTokenID = urlParams.get("activeToken");
      return activeTokenID;
    },
    getFromURI() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const fromURI = urlParams.get("fromURI");
      return fromURI;
    },
    async verifyRecoveryToken() {
      if (!this.activeToken) {
        this.errorParsingToken = true;
      } else {
        this.loading = true
        const payload = {
          recoveryToken: this.activeToken
        };

        try {
          const { data } = await axios.post(
            `https://${process.env.VUE_APP_OKTA_DOMAIN}/api/v1/authn/recovery/token`,
            JSON.stringify(payload),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          this.stateToken = data.stateToken;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.errorParsingToken = true;
          const errorMessage =
            err.response && err.response.data && err.response.data.errorSummary
              ? err.response.data.errorSummary
              : 'Unable to verify your identity.';
          this.$emit("displayAlert", errorMessage, true);
        }
      } 
    },
    async savePassword() {
      this.loading = true;
      const payload = {
        stateToken: this.stateToken,
        newPassword: this.newPassword
      };

      try {
        const { data } = await axios.post(
          `https://${process.env.VUE_APP_OKTA_DOMAIN}/api/v1/authn/credentials/reset_password`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );

        this.loading = false;
        this.$emit("displayAlert", 'Your account was created, please sign in', false);
        
        let redirectURL = window.location.protocol + '//' + window.location.host + '/login';
        if (this.fromUri !== null) {
          const sessionToken = data.sessionToken;
          redirectURL = `${this.fromUri}?sessionToken=${sessionToken}`;
        }
        this.$emit("redirectAfterResetPW", redirectURL);

      } catch (err) {
        this.loading = false;
        const errorMessage =
          err.response && err.response.data && err.response.data.errorSummary
            ? err.response.data.errorSummary
            : 'Password update failed';
        this.$emit("displayAlert", errorMessage, true);
      }
    }
  }
};
</script>

<style scoped>
.container-div {
  background-color:#fafafa !important;
}
</style>