<template>
  <div>
    <v-container fluid grid-list-xl>
      <h1 class="sectionTitle subheading">Promoted</h1>
      <div v-swiper="swiperOptionsObject" instance-name="promoted">
        <div v-if="chicklets.length" class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="app in chicklets"
            :key="app.productName"
          >
            <div>
              <v-card
                class="card pa-3"
                flat
                :key="app.sortOrder"
                background="#fafafa"
              >
                <v-btn
                  class="mx-3 pa-0"
                  dark
                  :width="chickletWidth"
                  :height="chickletHeight"
                  :color="checkIfSubscribed(app) ? '#09A1E0' : 'white'"
                  v-on:click="checkIfSubscribed(app)
                    ? buttonClick(app.loginUrl)
                    : createPopup(app)
                  "
                >
                  <v-list-item three-line>
                    <div id="bigAppContent">
                      <v-list-item-content class="ml-4">
                        <v-list-item-title
                          :class="[
                            'mt-4 ml-1 bigCardTitle',
                            checkIfSubscribed(app)
                              ? 'white--text'
                              : 'grey--text text--darken-3'
                          ]"
                          v-html="formatName(app.productName)" />
                        <v-btn
                          @click.native.stop.prevent
                          v-for="tag in app.tags"
                          :key="tag"
                          x-small
                          class="mb-2 ml-2"
                          height="20"
                          outlined
                          :color="checkIfSubscribed(app) ? 'white' : 'blue'"
                          :to="{ path: '/categories/' + tag.trim().toLowerCase() }"
                          >{{tag.trim()}}</v-btn>
                        <v-list-item-subtitle
                          :class="
                            checkIfSubscribed(app)
                              ? 'white--text'
                              : 'grey--text text--darken-1'
                          "
                          id="bigCardSubtitle"
                          class="ml-2"
                          >{{ app.desc }}</v-list-item-subtitle>
                        <v-card-actions
                          v-if="checkIfSubscribed(app)"
                          id="cardActions"
                        >
                          <v-btn
                            id="cardActions"
                            v-on:click="buttonClick(app.loginUrl)"
                            class="ml-2"
                            text
                            small
                            color="blue"
                          >Open App &#8594;</v-btn>
                        </v-card-actions>
                      </v-list-item-content>
                    </div>
                    <v-img
                      width="204"
                      height="205"
                      class="imgBorderLarge"
                      :src="app.img + imageSize.medium"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular indeterminate color="grey lighten-5" />
                        </v-row> 
                      </template>
                    </v-img>
                  </v-list-item>
                </v-btn>
              </v-card>
            </div>
          </div>
        </div>

        <div v-else class="swiper-wrapper">
          <v-card
            class="card pa-3"
            flat
            :height="chickletHeight"
          />
        </div>
        
        <div class="swiper-button-next" />
        <div class="swiper-button-prev" />
      </div>
    </v-container>
  </div>
</template>


<script>
import { directive } from "vue-awesome-swiper";
import { Swiper as SwiperClass, Navigation, Mousewheel } from "swiper/core";
//import "swiper-bundle.css";

SwiperClass.use([Navigation, Mousewheel]);

export default {
  directives: {
    swiper: directive,
  },
  props: {
    appsList: Array,
    subscribedList: Array,
    imageSize: Object,
  },
  data() {
    return {
      chickletWidth: "640",
      chickletHeight: "205",
      logoHeight: "33px",
      chicklets: [],
      swiperOptionsObject: {
        mousewheel: true,
        freeMode: true,
        slidesPerView: "auto",
        spaceBetween: -15,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {
    appsList: "getApps",
  },
  created() {
    this.getApps();
  },
  methods: {
    getApps() {
      this.chicklets = this.appsList.filter(app => app.promoted);
    },
    createPopup(app) {
      this.$emit("cardClick", app);
    },
    checkIfSubscribed(app) {
      return this.subscribedList.includes(app.oktaId);
    },
    buttonClick(href) {
      window.open(href, "_blank");
    },
    formatName(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1<wbr>$2');
    },
  },
};
</script>


<style scoped>
.subheading {
  padding-left: 12px;
  padding-top: 12px;
}
</style>
