const fieldValidations = {
  name: [
    v => !!v && !!v.trim() && v.includes(' ') && !v.endsWith(' ') || 'First and Last names are required'
  ],
  email: [
    v => !!v || "E-mail is required",
    v => /.+@.+\..+/.test(v) || "E-mail must be valid"
  ],
  phone: [
    v => !!v || 'Phone is required',
    v => (
      !!v &&
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,3})[-. )]*(\d{3,4})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(v)
      /**
       * SOURCE: https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
       * Allows formats:
       * 18005551234
       * 1 800 555 1234
       * +1 800 555-1234
       * +86 800 555 1234
       * 1-800-555-1234
       * 1 (800) 555-1234
       * (800)555-1234
       * (800) 555-1234
       * (800)5551234
       * 800-555-1234
       * 800.555.1234
       * 800 555 1234x5678
       * 8005551234 x5678
       * 1    800    555-1234
       * 1----800----555-1234
       */
    ) || "Phone Number must be valid (ie. 1-999-999-9999)"
  ],
  company: [
    v => !!v && !!v.trim() || 'Company is required'
  ],
  country: [
    v => !!v || 'Country is required'
  ],
  product: [
    v => !!v && v.length > 0 || 'Product is required'
  ],
  selectedHelpTopic: [
    v => !!v || 'Help Topic is required'
  ],
  message: [
    v => !!v || "Message is required",
    v => (
      !!v &&
      !!v.trim() &&
      v.match(/[^\s\n]/g).length >= 5
    ) || "Message is too short"
  ],
  password: [
    v => !!v || "Password is required",
    v => !!v && v.length >= 8 || "Passwords must be at least 8 characters",
    v => (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/).test(v) || "Password must include at least one capital letter, one lowercase letter, one and number.",
  ]
};

export default fieldValidations;
