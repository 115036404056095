var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":"","grid-list-xl":""}},[_c('h1',{staticClass:"sectionTitle subheading mt-8"},[_vm._v("Featured")]),_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.swiperOptionsObject),expression:"swiperOptionsObject"}],attrs:{"instance-name":"trending"}},[(_vm.chicklets.length)?_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.chicklets),function(app){return _c('div',{key:app.productName,staticClass:"swiper-slide ml-12 outer-container"},[_c('div',[_c('v-card',{key:app.sortOrder,staticClass:"card pa-3 inner-container",attrs:{"height":_vm.chickletHeight,"flat":"","background":"#fafafa"}},[_c('v-btn',{staticClass:"mx-3 pa-0",attrs:{"dark":"","width":_vm.chickletWidth,"height":_vm.chickletHeight,"color":_vm.checkIfSubscribed(app) ? '#09A1E0' : 'white'},on:{"click":function($event){_vm.checkIfSubscribed(app)
                  ? _vm.buttonClick(app.loginUrl)
                  : _vm.createPopup(app)}}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('div',{attrs:{"id":"trendingContent"}},[_c('v-list-item-content',{staticClass:"ml-4"},[_c('v-list-item-title',{class:[
                          'mt-4 trendingTitle',
                          _vm.checkIfSubscribed(app)
                            ? 'white--text'
                            : 'grey--text text--darken-3'
                        ],domProps:{"innerHTML":_vm._s(_vm.formatName(app.productName))}}),_vm._l((app.tags),function(tag){return _c('v-btn',{key:tag,staticClass:"mb-2 ml-2",attrs:{"x-small":"","height":"20","outlined":"","color":_vm.checkIfSubscribed(app) ? 'white' : 'blue',"to":{ path: '/categories/' + tag.trim().toLowerCase() }},nativeOn:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._v(_vm._s(tag.trim()))])}),_c('v-list-item-subtitle',{staticClass:"ml-2",class:_vm.checkIfSubscribed(app)
                            ? 'white--text'
                            : 'grey--text text--darken-1',attrs:{"id":"trendingSubtitle"}},[_vm._v(_vm._s(app.desc))]),(_vm.checkIfSubscribed(app))?_c('v-card-actions',{attrs:{"id":"trendingActions"}},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.buttonClick(app.loginUrl)}}},[_vm._v("Open App →")])],1):_vm._e()],2)],1),_c('v-img',{staticClass:"ml-2 fluid imgBorderLarge image-margin",attrs:{"width":"200px","height":_vm.chickletHeight,"eagar":"","postion":"right","src":app.img + _vm.imageSize.large},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)],1)],1)])}),0):_c('div',{staticClass:"swiper-wrapper"},[_c('v-card',{staticClass:"card pa-3",attrs:{"flat":"","height":_vm.chickletHeight}})],1),_c('div',{staticClass:"swiper-button-next"}),_c('div',{staticClass:"swiper-button-prev"})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }