<template>
<div>
  <v-container fluid grid-list-xl>
    <h1 class="sectionTitle ml-6 mt-8">Categories</h1>
    <div v-swiper="swiperOptionsObject" instance-name="categories">
      <div v-if="chicklets.length" class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="category in chicklets"
          :key="category"
        >
          <div>
            <v-card
              class="card pa-3"
              flat
              :category="category"
              background="#fafafa"
            >
              <router-link
                :to="{
                  name: 'categories',
                  params: { category: category }
                }"
                custom v-slot="{ navigate }"
              >
                <v-btn
                  @click="navigate"
                  class="mx-3 pa-0"
                  :width="chickletWidth"
                  :height="chickletHeight"
                  color="white"
                >
                  <v-list-item>
                    <v-list-item-content justify="center" align="center">
                      <v-list-item-title class="categoryCardTitle">{{ category }}</v-list-item-title>
                      <v-list-item-subtitle class="mt-0 appCount">{{ appsTracker[category] }} Applications</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-btn>
              </router-link>
            </v-card>
          </div>
        </div>
      </div>

      <div v-else class="swiper-wrapper">
        <v-card
          class="card pa-3"
          flat
          :height="chickletHeight"
        />
      </div>
      <div class="swiper-button-next" />
      <div class="swiper-button-prev" />
    </div>
  </v-container>
</div>
</template>


<script>
import { directive } from "vue-awesome-swiper";
import { Swiper as SwiperClass, Navigation, Mousewheel } from "swiper/core";
//import "swiper-bundle.css";

SwiperClass.use([Navigation, Mousewheel]);

export default {
  directives: {
    swiper: directive
  },
  props: {
    appsTracker: Object,
  },
  data() {
    return {
      chicklets: [],
      chickletWidth: "292",
      chickletHeight: "112",
      logoHeight: "33px",
      swiperOptionsObject: {
        mousewheel: true,
        freeMode: true,
        slidesPerView: 'auto',
        spaceBetween: -5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  watch: {
    appsTracker: "getApps",
  },
  created() {
    this.getApps();
  },
  methods: {
    getApps() {
      this.chicklets = Object.keys(this.appsTracker)
        .sort((a, b) => this.appsTracker[b] - this.appsTracker[a]);
    }
  }
};
</script>
