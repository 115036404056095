<script lang="ts">
export default {
  name: "ImplicitCallback",
  async beforeMount() {
    await this.$authService.handleRedirect();
  },
  render() {
    // typescript not allow empty render function, here's the workaround for renderless component
    return this.$scopedSlots.default && this.$scopedSlots.default({});
  }
};
</script>
