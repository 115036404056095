<template>
  <div>
    <Categories 
      :appsList="appsList"
      :subscribedList="subscribedList"
      :imageSize="imageSize"
      v-on:cardClick="setTargetApp"
    />
  </div>
</template>


<script>
  import Categories from '@/components/CategoriesPage'

  export default {
    name: 'categories',
    components: {
      Categories
    },
    props: {
      appsList: Array,
      subscribedList: Array,
      imageSize: Object,
    },
    methods: {
      setTargetApp(app) {
        this.$emit("cardClick", app);
      },
    }
  }
</script>
